import "./styles.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about1_6 from "../../assets/about1_6.png";
import about1_7 from "../../assets/about1_7.png";
import about1_8 from "../../assets/about1_8.png";
import about1_9 from "../../assets/about1_9.png";
import about1_10 from "../../assets/about1_10.png";
import about1_11 from "../../assets/about1_11.png";
import about1_12 from "../../assets/about1_12.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";
import about3_4 from "../../assets/about3_4.png";
import about3_5 from "../../assets/about3_5.png";
import about3_6 from "../../assets/about3_6.png";
import about3_7 from "../../assets/about3_7.png";
import about3_8 from "../../assets/about3_8.png";
import about3_9 from "../../assets/about3_9.png";
import about3_10 from "../../assets/about3_10.png";
import about3_11 from "../../assets/about3_11.png";
import about3_12 from "../../assets/about3_12.png";
import about3_13 from "../../assets/about3_13.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    img: about1_1,
    text: "Instalação e adequação padrão Copel em geral.",
  },
  {
    img: about1_2,
    text: "Instalação e manutenção elétrica residencial, comercial e industrial em geral.",
  },
  {
    img: about1_3,
    text: "Instalação de iluminação ",
  },
  {
    img: about1_4,
    text: "Luminotécnica e projetos",
  },
  {
    img: about1_5,
    text: "Instalação de ventiladores e exaustores.",
  },
  {
    img: about1_6,
    text: "Instalação de chuveiros e torneiras elétricas.",
  },
  {
    img: about1_7,
    text: "Tomadas, interruptores e disjuntores.",
  },
  {
    img: about1_8,
    text: "Quadros de distribuição",
  },
  {
    img: about1_9,
    text: "Adequação com aumento de carga.",
  },
  {
    img: about1_10,
    text: "Redimensionamento de circuitos.",
  },
  {
    img: about1_11,
    text: "Verificação de fuga de energia e alteração na fatura de energia elétrica",
  },
  {
    img: about1_12,
    text: "Aterramento elétrico",
  },
];

const dataSlide = [
  {
    id: 1,
    image: about3_1,
  },
  {
    id: 2,
    image: about3_2,
  },
  {
    id: 3,
    image: about3_3,
  },
  {
    id: 4,
    image: about3_4,
  },
  {
    id: 5,
    image: about3_5,
  },
  {
    id: 6,
    image: about3_6,
  },
  {
    id: 7,
    image: about3_7,
  },
  {
    id: 8,
    image: about3_8,
  },
  {
    id: 9,
    image: about3_9,
  },
  {
    id: 10,
    image: about3_10,
  },
  {
    id: 11,
    image: about3_11,
  },
  {
    id: 12,
    image: about3_12,
  },
  {
    id: 13,
    image: about3_13,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">Nossos Serviços</h2>

          <p className="text">
            Contamos com técnicos eletricistas e engenheiros experientes e
            comprometidos em sempre entregar os melhores resultados aos nossos
            clientes.
          </p>

          <div className="gallery">
            {data.map(({ img, text }, id) => {
              return (
                <div className="item" key={id}>
                  <figure className="left">
                    <img src={img} alt={text} />
                  </figure>

                  <p className="text">{text}</p>
                </div>
              );
            })}
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <h2 className="title">
            Instalação e montagem padrão <span className="yellow"> COPEL</span>
          </h2>

          <p className="text">
            Realizamos a montagem instalação de entrada de energia, comercial,
            industrial e residencial seguindo o padrão COPEL. Possuímos todas as
            certificações pertinentes à profissão exigidas por norma.
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="mobile">
        <div className="container">
          <CtaWpp />
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">Nossos Clientes</h2>

          <p className="text">
            Conheçam os cliente e parceiro que contratam e aprovam o nosso
            trabalho
          </p>

          <Swiper
            className="carousel"
            spaceBetween={10}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 3
                : 6
            }
            loop
            navigation
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {dataSlide.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </article>
    </section>
  );
};

export default About;
