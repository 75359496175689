import "./styles.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import header3_4 from "../../assets/header3_4.png";
import header3_5 from "../../assets/header3_5.png";
import header3_6 from "../../assets/header3_6.png";
import header3_7 from "../../assets/header3_7.png";
import header3_8 from "../../assets/header3_8.png";
import header3_9 from "../../assets/header3_9.png";
import header3_10 from "../../assets/header3_10.png";
import header3_11 from "../../assets/header3_11.png";

import CtaWpp from "../../components/CTAWpp";

SwiperCore.use([Navigation, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1,
  },
  {
    id: 2,
    image: header3_2,
  },
  {
    id: 3,
    image: header3_3,
  },
  {
    id: 4,
    image: header3_4,
  },
  {
    id: 5,
    image: header3_5,
  },
  {
    id: 6,
    image: header3_6,
  },
  {
    id: 7,
    image: header3_7,
  },
  {
    id: 8,
    image: header3_8,
  },
  {
    id: 9,
    image: header3_9,
  },
  {
    id: 10,
    image: header3_10,
  },
  {
    id: 11,
    image: header3_11,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            Especialistas em{" "}
            <span className="yellow">
              Montagem, Manutenção e Instalação Elétrica,
            </span>
            Comercial, Predial, Residencial e Industrial.
          </h1>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <p className="text">
            <span className="yellow">Atendimento </span>
            24h{" "}
            <span className="yellow">
              em Curitiba, Região metropolitana e Litoral
            </span>
             
          </p>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <div className="left">
            <h2 className="title">Somos a Golden Elétrica</h2>

            <Swiper
              className="mobile"
              spaceBetween={0}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 1
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <p className="text">
              Atuamos no mercado desde 2012, possuímos{" "}
              <b> técnicos eletricistas e engenheiros experientes,</b> sempre
              visando a satisfação e o melhor atendimento de nossos clientes,
              empregando melhorias e constante atualizações de nossos serviços.{" "}
              <b> Entre as nossa especialidades, estão:</b>
            </p>

            <ul>
              <li>Instalação de postes padrão Copel;</li>
              <li>Instalação de sistema de aterramento elétrico;</li>
              <li>Execução de projetos elétricos;</li>
              <li>Instalação e realização de projetos luminotécnico.</li>
            </ul>

            <p className="text2">
              <b>
                Possuímos todas as certificações pertinentes à profissão
                exigidas por norma.
              </b>
            </p>

            <CtaWpp />
          </div>

          <Swiper
            className="carousel"
            spaceBetween={0}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 1
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </article>
      </section>
    </header>
  );
};

export default Header;
