import React from "react";
import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import logo_topo from "../../assets/logo_topo.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure className="left">
          <img src={logo_topo} alt="Logo" />
        </figure>

        <article className="right">
          <CtaWpp text={"(41) 99112-3598"} />
          <CtaTel />
        </article>
      </section>
    </nav>
  );
};

export default Menu;
