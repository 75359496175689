import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import content2 from "../../assets/content2.png";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <p className="text">
             Atendimento <span> 24h </span> em Curitiba, Região metropolitana e
            Litoral
          </p>
        </div>
      </article>

      <article className="content2">
        <div className="container vertical">
          <h2 className="title">Formas de Pagamento</h2>

          <p className="text">
            Dinheiro, pix, transferência bancária, cartão de crédito e débito.
          </p>

          <figure className="bottom">
            <img src={content2} alt="Formas de Pagamento" />
          </figure>

          <CtaWpp />
        </div>
      </article>
    </section>
  );
};

export default Content;
