import React from "react";
import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTel from "../../components/CTATel";

import logo_rodape from "../../assets/logo_rodape.png";
import footer from "../../assets/footer.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="left">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <div className="middle">
            <p className="text">
              {" "}
              <b>Siga-nos no instagram:</b>
            </p>

            <div className="social">
              <figure>
                <img src={footer} alt="Ícone do Instagram" />
              </figure>

              <a
                href="https://www.instagram.com/goldenservicoseletricos/"
                target="_blank"
                rel="noreferrer"
              >
                @goldenservicoseletricos
              </a>
            </div>
          </div>

          <div className="right">
            <CtaWpp text={"(41) 99112-3598"} />
            <CtaTel />
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            Golden Elétrica © 2024 – Todos os direitos reservados
          </p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
